const Matter = require("matter-js");
const matterAttractors = require("matter-attractors");
Matter.use(
  "matter-attractors" // PLUGIN_NAME
);
const CustomRenderer = require("./render");

document.addEventListener("keyup", function (e) {
  if (e.keyCode === 71) {
    document.querySelector(".grid-overlay").classList.toggle("show");
  }
});

const videoControls = document.querySelectorAll(".video-block .controls .button");
Array.from(videoControls).forEach(function (element) {
  element.addEventListener("click", function (e) {
    let videoBlock = element.parentNode.parentNode;
    let video = videoBlock.querySelector("iframe");
    var player = new Vimeo.Player(video);

    if (!videoBlock.classList.contains("playing")) {
      videoBlock.classList.add("playing");
      player.play();
    }

    player.on("pause", function () {
      videoBlock.classList.remove("playing");
    });

    e.preventDefault();
    return false;
  });
});

const logoBlocks = document.querySelectorAll(".logo-block");
Array.from(logoBlocks).forEach(function (element) {
  element.addEventListener("mousemove", function (e) {
    let circle = element.querySelector(".explore-more");
    let bounds = element.getBoundingClientRect();
    let x = e.clientX - bounds.left;
    let y = e.clientY - bounds.top;
    circle.style.left = Math.round(x) + "px";
    circle.style.top = Math.round(y) + "px";
  });
});

const options = document.querySelectorAll(".type-radio label");
Array.from(options).forEach(function (element) {
  element.addEventListener("click", function (e) {
    Array.from(options).forEach(function (element) {
      element.classList.remove("selected");
    });
    element.classList.add("selected");
  });
});

const sliders = document.querySelectorAll(".testimonials-slider");
Array.from(sliders).forEach(function (element) {
  let duration = 6;
  let interval = 30;
  let percentTime, step, tick;
  let isPaused = false;
  let progressCircle = element.parentNode.querySelector(".progress-ring .progress-ring__circle");
  let flkty = new Flickity(element, {
    cellSelector: ".testimonial",
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true,
  });

  flkty.on("change", function (event, index) {
    startProgressbar();
  });

  // element.addEventListener("mouseenter", function () {
  //   isPaused = true;
  // });

  // element.addEventListener("mouseleave", function () {
  //   isPaused = false;
  // });
  function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    isPaused = false;
    tick = window.setInterval(increase, interval);
  }

  function increase() {
    if (!isPaused) {
      step = (duration * 1000) / interval;
      percentTime += 100 / step;
      let value = (107 / 100) * percentTime * -1;
      progressCircle.setAttribute("stroke-dashoffset", Math.round(value));
      if (percentTime >= 100) {
        flkty.next();
        startProgressbar();
      }
    }
  }

  function resetProgressbar() {
    progressCircle.setAttribute("stroke-dashoffset", "107");
    clearTimeout(tick);
  }

  startProgressbar();
});

window.addEventListener("DOMContentLoaded", (event) => {
  let results = document.querySelector(".results-module");
  if (results) {
    var Engine = Matter.Engine,
      Events = Matter.Events,
      Runner = Matter.Runner,
      Render = CustomRenderer,
      World = Matter.World,
      Body = Matter.Body,
      MouseConstraint = Matter.MouseConstraint,
      Mouse = Matter.Mouse,
      Common = Matter.Common,
      Bodies = Matter.Bodies;

    var engine = Engine.create({
      render: {
        controller: CustomRenderer,
      },
    });

    let width = window.innerWidth;
    let height = window.innerHeight;

    var render = Render.create({
      element: results,
      engine: engine,
      options: {
        width: width,
        height: height,
        wireframes: false,
        pixelRatio: 1,
        background: "transparent", // <-- important
      },
    });

    var runner = Runner.create();
    Runner.run(runner, engine);
    Render.run(render);

    var world = engine.world;
    world.gravity.scale = 0;

    var attractiveBody = Bodies.circle(
      render.options.width / 2,
      render.options.height / 2,
      10,
      {
        isStatic: true,
        render: {
          fillStyle: "transparent",
        },
        plugin: {
          attractors: [
            function (bodyA, bodyB) {
              return {
                x: (bodyA.position.x - bodyB.position.x) * 0.00007,
                y: (bodyA.position.y - bodyB.position.y) * 0.00007,
              };
            },
          ],
        },
      },
      256
    );

    World.add(world, attractiveBody);

    // var circle1 = Bodies.circle(render.options.width / 4, render.options.height / 4, render.options.width * 0.06, { render: { fillStyle: "#D8886C" }, title: "Savings & Investment" }, 256);
    var circle2 = Bodies.circle(render.options.width / 3.75, render.options.height / 3.75, render.options.width * (29.21 / 100 / 2), { render: { fillStyle: "#EAB595" }, title: "Energy" }, 256);
    var circle3 = Bodies.circle(render.options.width / 4, render.options.height / 2, render.options.width * (12.14 / 100 / 2), { density: 0.001, render: { fillStyle: "#AE7153" }, title: "Food" }, 256);
    var circle4 = Bodies.circle(render.options.width / 3.75, render.options.height / 1.25, render.options.width * (14.23 / 100 / 2), { density: 0.001, render: { fillStyle: "#DE5333" }, title: "Shopping" }, 256);
    var circle5 = Bodies.circle(render.options.width / 1.25, render.options.height / 2, render.options.width * (44.41 / 100 / 2), { render: { fillStyle: "#D23745" }, title: "Transport" }, 256);

    var topWall = Bodies.rectangle(render.options.width / 2, 0, render.options.width, 50, { isStatic: true, render: { fillStyle: "transparent" } });
    var rightWall = Bodies.rectangle(render.options.width, render.options.height / 2, 50, render.options.height, { isStatic: true, render: { fillStyle: "transparent" } });
    var bottomWall = Bodies.rectangle(render.options.width / 2, render.options.height, render.options.width, 50, { isStatic: true, render: { fillStyle: "transparent" } });
    var leftWall = Bodies.rectangle(0, render.options.height / 2, 50, render.options.height, { isStatic: true, render: { fillStyle: "transparent" } });

    // setTimeout(function () {
    //   World.add(world, circle1);
    // }, 100);
    setTimeout(function () {
      World.add(world, circle2);
    }, 200);
    setTimeout(function () {
      World.add(world, circle3);
    }, 300);
    setTimeout(function () {
      World.add(world, circle4);
    }, 400);
    // World.add(world, circle2);
    // World.add(world, circle3);
    // World.add(world, circle4);
    World.add(world, circle5);
    World.add(world, topWall);
    World.add(world, rightWall);
    World.add(world, bottomWall);
    World.add(world, leftWall);

    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });

    World.add(world, mouseConstraint);

    Events.on(mouseConstraint, "mousedown", (e) => {
      // Matter.Body.scale(e.source.body, 1.3, 1.3);
      console.log(e.source.body);
      // circle1.render.opacity = 0.2;
      circle2.render.opacity = 0.2;
      circle3.render.opacity = 0.2;
      circle4.render.opacity = 0.2;
      circle5.render.opacity = 0.2;
      e.source.body.render.opacity = 1;
      // Matter.Body.set(e.source.body, render.opacity, 0.2);
    });

    render.mouse = mouse;

    mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
    mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);

    window.addEventListener("resize", function () {
      render.canvas.width = window.innerWidth;
      render.canvas.height = window.innerHeight * 0.8;
    });
  }
});
